<template>
  <div class=" pb-6 home-page">
    <NavBar :home-data="homeData"/>
    <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
      <div class="flex justify-center">
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
<!--          <h2 class="text-bold text-3xl leading-6 font-medium text-gray-900 text-center" id="modal-headline">-->
<!--            {{ currentPage.title }}-->
<!--          </h2>-->
          <div class="mt-8 mb-8">
            <div id="content">
              <section class="text-lg leading-loose text-black">
                <vue-markdown :key="currentPage.title">{{ currentPage.content }}</vue-markdown>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import VueMarkdown from 'vue-markdown'

export default {
  name: 'OrgHomePage',
  data() {
    return {
      homeData: null,
    }
  },
  components: {NavBar, VueMarkdown},
  methods: {
    checkOrganisation() {
      this.$store.dispatch('auth/fetchOrgDetails')
        .then((data) => {
          this.balancesError = false
          this.fetchingBalances = false
          if (data.message_type == 1) {
            this.loadData()
          } else {
            // this.$router.push({ path: '/pages/error-404' })
          }
        })
        .catch((error) => {
          this.$router.push({path: '/pages/error-404'})
        })
    },
    loadData() {
      const data = localStorage.getItem('home')
      this.homeData = JSON.parse(data)
      this.pages = this.homeData.pages
      try {
        this.homeData.pages.forEach(p => {
          if (p.title === 'Impressum') {
            this.currentPage = p;
          }
        })
      } catch (e) {
      }
    },
  },
  mounted() {
    const data = localStorage.getItem('home')

    if (data) {
      this.loadData()
    } else {
      this.checkOrganisation()
    }
  },

}
</script>

<style>
h3, h4 {
  padding-top: 12px;
}
</style>
